<template>
  <div class="unionTro">
    <div class="content" v-html="info">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      default: require("@/assets/img/recent.png"),
    },
  },
};
</script>

<style scoped lang="scss">
.unionTro {
  width: 100%;
  padding: 20px;
}
.content {
  font-size: 30px;
  background-color: white;
  min-height: calc(100vh - 130px);
  padding: 10px;
  line-height: 30px;
  box-sizing: border-box;
  ::v-deep {
    img {
      width: 100%;
    }
  }
}
</style>
