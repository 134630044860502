<template>
  <div class="notice">
    <div class="workGoal">
      <van-tabs title-active-color="blue" color="blue" v-model="active">
        <van-tab title="社团简介">
          <union-troduc :info="infoList.communityDesc" />
        </van-tab>
        <van-tab title="机构简介">
          <union-troduc :info="infoList.organizationDesc" />
        </van-tab>
        <van-tab title="组织架构">
          <union-troduc :info="infoList.organizationChart" />
        </van-tab>
        <van-tab title="日常活动">
          <union-troduc :info="infoList.dailyActivity" />
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import topBar from "@/components/topBar/topBar";
import unionTroduc from "./unionTroduc.vue";

export default {
  components: {
    topBar,
    unionTroduc,
  },
  data() {
    return {
      active: "",
      infoList: [],
    };
  },
  methods: {
    getDatalist() {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      let id = this.$route.query.id;
      this.$http({
        url: this.$http.adornUrl("/wxapp/app/appjqalliance/info/" + id),
        method: "post",
      }).then(
        ({ data }) => {
          if (data.code == 0) {
            this.infoList = data.appJqAlliance;
            // 加载状态结束
            this.loading = false;
            this.$toast.clear();
          }
          this.$toast.clear();
        },
        (err) => {
          this.$toast.clear();
        }
      );
    },
  },
  mounted() {
    this.getDatalist();
  },
};
</script>

<style scoped>
.notice {
  width: 100vw;
  height: 100vh;
}
.workGoal {
  width: 100vw;
  height: 100vh;
  background-color: #f6f5f3;
  /* margin-top: 102px; */
}
</style>
